.pointer {
    cursor: pointer;
}

.strike {
    text-decoration: line-through;
}

.form-hint {
    color: #b5b5b5;
    font-size: 70%;
}


.tag.light-primary {
    background: #5fe0cd;
    color: #fff;
}

.tag.light-info {
    background: #7dc4f3;
    color: #fff;
}

.tag.light-danger {
    background: #fb6262d1;
    color: #fff;
}

.tag.light-gray {
    background: #858484;
    color: #fff;
}

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}
